import '../../styles/pages/fall-winter-2021/coph-timeline.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2021-stories/more-stories"
import Gallery from "react-photo-gallery";

import socialBanner from "../../images/fall-winter-2021/social-banners/coph-timeline.jpg";

import hero from '../../images/fall-winter-2021/coph-timeline/hero2.jpg';

import gallery1 from '../../images/fall-winter-2021/coph-timeline/coph1.jpg';
import gallery2 from '../../images/fall-winter-2021/coph-timeline/coph2.jpg';
import gallery3 from '../../images/fall-winter-2021/coph-timeline/coph3.jpg';
import gallery4 from '../../images/fall-winter-2021/coph-timeline/coph4.jpg';
import gallery5 from '../../images/fall-winter-2021/coph-timeline/coph5.jpg';


export default class Index extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    var pageTitle = 'Through the Years: A Center for Oral and Public History Timeline';
    var pageDesc = 'The Lawrence de Graaf Center for Oral and Public History maintains the largest regionally focused oral history archive in California with over 6,000 recorded interviews, related transcripts, photographs and other materials.';
    var slug = 'coph-timeline';


    var heroStyles = {
      backgroundColor: '#222',
      backgroundImage: `url(${hero})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    const photos = [
      {
        src: gallery1,
        width: 1200,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: gallery2,
        width: 1200,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: gallery3,
        width: 1200,
        height: 800,
        alt: '',
        title: ''
      },
      {
        src: gallery4,
        width: 800,
        height: 1200,
        alt: '',
        title: ''
      },
      {
        src: gallery5,
        width: 1200,
        height: 800,
        alt: '',
        title: ''
      }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeInDown delay-1s">{pageTitle}</h1>
            <span className="animated fadeInDown delay-1s byline">By Kendra Morales, Photos by Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
        </div>
        <article className="wrap small">

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>Established in 1968, the <a href="https://coph.fullerton.edu/" target="_blank" ref="noreferrer">Lawrence de Graaf Center for Oral and Public History</a> maintains the largest regionally focused oral history archive in California with over 6,000 recorded interviews, related transcripts, photographs and other materials.</p>
          </ScrollAnimation>
          
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className="align-wide gallery">
            <Gallery photos={photos}  />
            <figcaption>Lawrence de Graaf Center for Oral and Public History at Cal State Fullerton</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>The center stands out nationally for its focus on marginalized communities, grassroots organizing, student-driven projects and extensive records on Southern California history. In addition to preserving the historical memories of local individuals, the program trains students in oral history methods, museum curation, historic preservation, public art and culture, oral history performance, heritage tourism, digital history, and archival management.</p>
          </ScrollAnimation>

          <ul className="timeline">
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">1960s</span>
              <p>CSUF’s Oral History Program is established in 1968 by history professor Gary L. Shumway, with support from history professor Lawrence de Graaf and the Department of History. The first oral histories in the collections include de Graaf’s interviews with African Americans and CSUF founding faculty and administrators.</p>
              
              <p>With the election of Richard Nixon as president in 1968, Shumway, de Graaf and history professor Harry Jeffrey establish the Richard M. Nixon Oral History Project. Fourteen students compile over 160 interviews with relatives, friends, teachers, classmates and associates of Nixon during his early years.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">1970s</span>
              <p> History professor Art Hansen and de Graaf launch the Japanese American Oral History Project in 1972 with the help of student Betty E. Mitson, highlighting the World War II stories of Orange County's Japanese American community.</p> 
              
              <p>Hansen is named director of the Oral History Program in January 1975. De Graaf becomes the program’s third director in 1979.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">1980s</span>
              <p>De Graaf initiates the Oral History Program’s first public history project, the Brea Project, rejuvenating the Brea Historical Society and producing a museum exhibit on Brea history. </p>
              
              <p>Students conduct oral histories of residents in Anaheim, Brea, El Toro, Fullerton, Huntington Beach, Orange, Santa Ana, Placentia, Tustin and Yorba Linda throughout the decade.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">1990s</span>
              <p>Hansen serves as director of the program again from 1991 to 2008.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">2000s</span>
              <p>The Oral History Program becomes a campuswide center and is renamed the Center for Oral and Public History in 2002.</p>
            
              <p>COPH produces an anthology on the Orange County Mexican American experience during World War II in 2004. Students conduct 80 interviews on the subject with training from Hansen and history professor Wendy Elliott-Scheinberg.</p>
            
              <p>The El Toro Marine Air Station Oral History Project launches in January 2007 with funding from the Orange County Great Park Corp. Over 500 interviews record the history of Orange County from both military and civilian perspectives.</p>
              
              <p>History professor Natalie Fousekis is named the center’s director in 2008 after Hansen retires.</p>
            
              <p>The Hansen Lectureship and Fellowship is created, inviting national leaders in the field to give lectures and to support students pursuing oral and public history projects.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">2010s</span>
              <p>The “From Hitler's Europe to the Golden State” Oral History Project, led by history professor Cora Granata, launches in spring 2011.</p>
              
              <p>COPH’s Women, Politics and Activism Project begins in spring 2015 with a grant from the John Randolph Haynes and Dora Haynes Foundation. Students conduct interviews with women activists and former elected officials for the project, amassing over 320 oral histories.</p>
              
              <p>In 2017, COPH is renamed after Lawrence de Graaf in honor of $1 million in gifts and pledges from de Graaf and his late wife, Shirley. </p>
              
              <p>The center celebrates its 50th anniversary in 2018.</p>
            </li>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <li>
              <span className="date">2020s</span>
              <p>COPH moves into a <a href="http://news.fullerton.edu/2021/04/center-for-oral-and-public-historys-new-space-preserves-stories-for-future-generations/" target="_blank" rel="noreferrer">new, modernized facility</a> on the sixth floor of the Pollak Library in March 2021. It features an updated archival space with its own HVAC system, a reading room for researchers, a collaborative workroom for students, a community room for events and exhibits, a processing room for handling and organizing new materials, and a recording room. <span className="end-mark" /></p>
            </li>
            </ScrollAnimation>
          </ul>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p className="learn-more">Support the College of Humanities and Social Sciences<br /><a href="https://campaign.fullerton.edu/hss" target="_blank">campaign.fullerton.edu/hss</a></p>
          </ScrollAnimation>
        </article>
        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 